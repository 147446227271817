body {
  background-color: #593209;
background-image: url(wood-pattern.png);
/* This is mostly intended for prototyping; please download the pattern and re-host for production environments. Thank you! */
}

.App {
  text-align: center;
}

