@import url('https://fonts.cdnfonts.com/css/signpainter');

.controlsContainer {
	padding-top: 40px;
	width: 100%;
	gap: 30px;
	justify-content: center;
}
.App {
	display: flex;
	padding: 50px;
	gap: 30px;
}
.canva {
  background-color: #666666;
  border-radius: 10px;
}
.solos {
	display: flex;
	padding: 30px;
	justify-content: space-around;
  align-items: center;
	button {
		height: 100px;
		width: 100px;
		border-radius: 50px;
		svg {
			transform:  scale(2.5);
		}
	}
}
.fulltrackcontainer {
	display:  flex;
	flex-direction: column ;
	justify-content: space-between;
}
.rightPanel {
	display:  flex;
	flex-direction: column;
	gap: 30px;
	width: 40%;
	justify-content: space-between;
}
.nameplate {
	font: "SignPainter";
	font-family: 'SignPainter';
	font-size: 42px;
	font-weight: 800;
	font-weight: bold;
  text-shadow: 0px 1px 0px rgba(255,255,255,.3), 0px -1px 0px rgba(0,0,0,.7);
}
.panel {
	display: flex;
	flex-direction:  column;
	border: 2px double #222222;
	box-shadow:  0 0 4px 2px #CCC;
	div {
		display:  flex;
		position:  relative;
	}
	position: relative;
}
.rivet {
	position: absolute !important;
	content:  '';
	border:  1px solid black;
	border-radius: 50%;
	height: 5px;
	width: 5px;
	box-shadow: inset 0 0 1px 1px #555;
	&.tl {
		top: 10px;
		left: 10px;
	}
	&.tr {
		top: 10px;
		right: 10px;
	}
	&.bl {
		bottom: 10px;
		left: 10px;
	}
	&.br {
		bottom: 10px;
		right: 10px;
	}
}
.sliders {
	height: 350px;
	width: 600px;
	gap: 30px;
	justify-content: center;
	flex-direction:  row-reverse;
}
label {
	margin-bottom: 30px;
	background-color: white;
	font-family: monospace;
	padding: 5px;
	transform: rotate(3deg);
}

.metal {
  outline: none;
  
  color: hsla(0,0%,20%,1);
  text-shadow: hsla(0,0%,40%,.5) 0 -1px 0, hsla(0,0%,100%,.6) 0 2px 1px;
  
  background-color: hsl(0,0%,90%);
  box-shadow: inset hsla(0,0%,15%,  1) 0  0px 0px 4px, /* border */
    inset hsla(0,0%,15%, .8) 0 -1px 5px 4px, /* soft SD */
    inset hsla(0,0%,0%, .25) 0 -1px 0px 7px, /* bottom SD */
    inset hsla(0,0%,100%,.7) 0  2px 1px 7px, /* top HL */
    
    hsla(0,0%, 0%,.15) 0 -5px 6px 4px, /* outer SD */
    hsla(0,0%,100%,.5) 0  5px 6px 4px; /* outer HL */ 
  
  transition: color .2s;
}

.metal.linear {
	background-size: 50px 600px;
  background-image: -webkit-repeating-linear-gradient(left, hsla(0,0%,100%,0) 0%, hsla(0,0%,100%,0)   6%, hsla(0,0%,100%, .1) 7.5%),
    -webkit-repeating-linear-gradient(left, hsla(0,0%,  0%,0) 0%, hsla(0,0%,  0%,0)   4%, hsla(0,0%,  0%,.03) 4.5%),
    -webkit-repeating-linear-gradient(left, hsla(0,0%,100%,0) 0%, hsla(0,0%,100%,0) 1.2%, hsla(0,0%,100%,.15) 2.2%),
    
    linear-gradient(180deg, hsl(0,0%,78%)  0%, 
    hsl(0,0%,90%) 47%, 
    hsl(0,0%,78%) 53%,
    hsl(0,0%,70%)100%);
}


$bg: #fff;
$text: #382b22;
$light-pink: #fff0f0;
$pink: #ffe9e9;
$dark-pink: #f9c4d2;
$pink-border: #b18597;
$pink-shadow: #ffe3e2;

$background: #f8f8f8;
$button-gradient: #D8D9DB 0%, #fff 80%, #FDFDFD 100%;
$button-border: #8F9092;
$button-text: #606060;

$highlight-bottom: 0 0 3px 1px #FCFCFC;
$shadow-bottom: 0 0 8px #D6D7D9;
$shadow-top: 0 -4px 4px #CECFD1;
$highlight-top: 0 -6px 4px #FEFEFE;
$shadow-inset: inset 0 0 3px 0 #CECFD1;
$shadow-inset-hover: inset 0 0 3px 3px #CECFD1;
$shadow-inset-focus: inset 0 0 10px 0px rgba(0, 0, 250, .6);
$shadow-inset-active: inset 0 0 5px 3px #999, inset 0 0 30px #aaa;

$button-shadow-base: $highlight-bottom, $shadow-bottom;
$button-shadow-default: $button-shadow-base, $shadow-inset;
$button-shadow-hover: $button-shadow-base, $shadow-inset-hover;
$button-shadow-focus: $button-shadow-base, $shadow-inset-focus;
$button-shadow-active: $button-shadow-base, $shadow-inset-active;

@keyframes active {
  from {
    box-shadow: $button-shadow-focus;
  }
  to {
    box-shadow: $button-shadow-hover;
  }
}
button, [role="button"] {
  -webkit-appearance: none;
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  cursor: pointer;
  width: 50px;
  height: 50px;
  background-image: linear-gradient(to top, $button-gradient);
  border-radius: 30px;
  border: 1px solid $button-border;
  transition: all .2s ease;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 14px;
  font-weight: 600;
  color: $button-text;
  text-shadow: 0 1px #fff;
  &::-moz-focus-inner {
    border: 0;
  }
  & > * {
    transition: transform .2s ease;
  }
  &:hover:not([disabled]) {
    box-shadow: $button-shadow-hover;
    & > * {
      transform: scale(.975);
    }
   }
  &:focus:not(:active){
    animation: active .9s alternate infinite;
    outline: none;
  }
  &:active:not([disabled]){

    box-shadow: $button-shadow-active;
    & > * {
      transform: scale(.95);
    }
    }
  &:disabled{
    opacity: 0.6;
    cursor: not-allowed;
  }
  &.icon{
    width: 50px;
    svg {
      margin-top: 3px;
      width: 30px;
      height: 30px;
    }
  }
  &.active {
 		color: #66FF00;
  	box-shadow: 0 0 10px 5px #66ff00;
  }
}

$val: 320Hz 600Hz 1KHz 3KHz 6KHz 12KHz;

$input-bw: 1.5em;
$input-h: 2.25em;
$input-bg-c: #888888;
$input-bg-ct: rgba($input-bg-c, 0);

$ruler-line-w: .0625em;
$ruler-line-h: .625em;
$ruler-line-off: ($input-bw - $ruler-line-h)/2;
$ruler-line-c: #cccccc;
$ruler-fs: .75;

$track-u: 2em;
$track-k: 6;
$track-xtra: 1em;
$track-w: $track-k*$track-u + $track-xtra;
$track-h: .5em;

$thumb-w: 4em;
$thumb-h: 2em;
$thumb-r: .75em;

@mixin track() {
  width: $track-w; height: $track-h;
  border-radius: .1875em;
  background: mix(rgba(#eeeeee, .65), #dbdbdb);
}

@mixin track-focus() {
  background: #898586;
}

@mixin thumb() {
  border: none;
  width: $thumb-w; height: $thumb-h;
  border-radius: .5em;
  box-shadow:
     -.125em 0 .25em #928886, 
    inset -1px 0 1px #fff;
  background: 
    radial-gradient(#ebe1e0 10%, rgba(#ebe1e0, .2) 10%, rgba(#ebe1e0, 0) 72%) 
      no-repeat 50% 50%, 
    radial-gradient(#{at 100% 50%}, #eeeeee, #dedede 71%, transparent 71%) 
      no-repeat ($thumb-w - 2*$thumb-r) 50%, 
    linear-gradient(90deg, #eeeeee, #cccccc) no-repeat 100% 50%,
    radial-gradient(#{at 0 50%}, #cccccc, #bababa 71%, transparent 71%) 
      no-repeat $thumb-r 50%, 
    linear-gradient(90deg, #eeeeee, #cccccc) no-repeat 0 50%,
    linear-gradient(#c0c0c0, #fcfcfc, #fcfcfc, #c0c0c0);
  background-size: 1.1*$thumb-r 100%;
}

.trackContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  transform: rotate(-90deg);
  width: 100px;
  height: 10px;
  margin-top: 150px;
}

input[type='range'] {
  &, 
  &::-webkit-slider-runnable-track, 
  &::-webkit-slider-thumb {
    -webkit-appearance: none;
  }
  
  align-self: center;
  margin: -$input-bw/2 0;
  border: solid $input-bw transparent;
  padding: 0;
  width: $track-w; height: $input-h;
  background: 
    repeating-linear-gradient(90deg, 
        $ruler-line-c, $ruler-line-c $ruler-line-w, 
        transparent $ruler-line-w, transparent $track-u) 
      no-repeat 50% $ruler-line-off border-box, 
    repeating-linear-gradient(90deg, 
        $ruler-line-c, $ruler-line-c $ruler-line-w, 
        transparent $ruler-line-w, transparent $track-u) 
      no-repeat 50% $ruler-line-off + $input-h + $input-bw border-box, 
    linear-gradient(90deg, $input-bg-ct, $input-bg-c, $input-bg-ct) 
      no-repeat 50% 50% border-box;
  background-size: 
    $track-k*$track-u + $ruler-line-w $ruler-line-h, 
    $track-k*$track-u + $ruler-line-w $ruler-line-h, 
    100% $input-h;
  font-size: 1em;
  cursor: pointer;
  
  &::-webkit-slider-runnable-track {
    position: relative;
    @include track();
  }
  &::-moz-range-track {
    @include track();
  }
  &::-ms-track {
    border: none;
    @include track();
    color: transparent;
  }
  
  &::-ms-fill-lower { display: none; }
  
  &::-webkit-slider-thumb {
    margin-top: ($track-h - $thumb-h)/2;
    @include thumb();
  }
  &::-moz-range-thumb {
    @include thumb();
  }
  &::-ms-thumb {
    @include thumb();
  }
  
  &::-webkit-slider-runnable-track, >>> #track {
    &:before, &:after {
      position: absolute;
      font: #{$ruler-fs*1em}/#{3*$track-u/$ruler-fs} 
        trebuchet ms, arial, sans-serif;
    }
    &:before {
      top: 50%; right: 100%;
      transform: translate(50%, -50%) rotate(90deg) translate(0, 32%);
    }
    &:after {
      left: 50%;
      width: 3em;
      word-spacing: 1em;
    }
  }
  
  @for $i from 1 through 6 {
    &:nth-of-type(#{$i}) {
      &::-webkit-slider-runnable-track, >>> #track {
        &:before {
          content: '#{nth($val, $i)}';
        }
        &:after {
          @if $i == 1 {
            bottom: 100%;
            transform: translate(-50%, 50%) rotate(90deg) 
              translate(-$input-bw - $input-h - $ruler-line-h);
            text-align: right;
          }
          @if $i == 6 {
            top: 100%;
            transform: translate(-50%, -50%) rotate(90deg) 
              translate($input-bw + $input-h + $ruler-line-h);
          }
        }
      }
    }
  }
  
  &:nth-of-type(1), &:nth-of-type(6) {
    &::-webkit-slider-runnable-track, >>> #track {
      &:after {
        content: '+18 0 -18';
      }
    }
  }
  
  &:focus {
    outline: none;

    &::-webkit-slider-runnable-track {
      @include track-focus();
    }
    &::-moz-range-track {
      @include track-focus();
    }
    &::-ms-track {
      @include track-focus();
    }
  }
}
